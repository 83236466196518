body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    background-color: #f7f7f7;
  }
  
  
  
  nav {
    display: flex;
    padding: 0.5% 2%;
    justify-content: space-between;
    align-items: center;
  }

  .clickable-area {
    display: inline-flex; 
    align-items: center;
    cursor: pointer;
}
  
  .logo {
    width: 150px;
    color: #fff;
    text-decoration: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .logo span {
    margin-left: 10px;
  }
  
  .nav-links {
    flex: 1;
    text-align: center;
    text-align: right;
    padding-top: 10px;
  }
  
  .nav-links ul li {
    list-style: none;
    display: inline-block;
    justify-items: center;
    align-items: center;
    padding: 10px 12px;
    position: relative;
  }
  
  .nav-links ul li::after {
    content: '';
    width: 0%;
    height: 2px;
    background: #f44336;
    display: block;
    margin: auto;
    transition: 0.5s;
  }
  
  .nav-links ul li:hover::after {
    width: 100%;
  }
  
  .nav-links ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
  }
  
  nav a {
    text-decoration: none;
  }
  
  nav i {
    color: blue;
  }
  
  .container {
    width: 100%;
    min-height: 100vh;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(background.png);
    background-position: cover;
    background-size: cover;
    position: relative;
  }
  
  .container-home {
    width: 100%;
    min-height: 100vh;
    background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(bg_home1.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
  }
  
  .form-box {
    width: 90%;
    max-width: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 25px 25px 25px 25px;
    text-align: center;
    border-radius: 12px;
    opacity: 0.95;
  }
  
  .form-box-home h1 {
    color: white;
    font-size: 40px;
    font-weight: bold;
    position: relative;
    margin-top: 180px;
    margin-left: 1060px;
  }

  .form-box-home .static-text {
    position: absolute;
    bottom: 200px; 
    right: 40px; 
    color: white; 
    font-size: 30px;
  }
  
  .form-box-home #changeText {
    position:absolute;
    bottom: 150px;
    right:40px;
    display: block; 
    color: blue; 
    font-size: 30px; 
    margin-top: 10px;
  }
  
  @media screen and (max-width: 768px) {
    .static-text, #changeText {
      font-size: 20px; 
      right: 10px;
      bottom: 10px;
    }
  }

  .form-box h1 {
    font-size: 30px;
    margin-bottom: 25px;
    color: #3c00a0;
    position: relative;
  }
  
  .form-box h1::after {
    content: '';
    width: 30px;
    height: 4px;
    border-radius: 3px;
    background: #3c00a0;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .input-field {
    background: #eaeaea;
    margin: 25px 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    max-height: 55px;
    transition: max-height 0.5s;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
  }
  
  .input-field input {
    padding-right: 130px;
  }
  
  input {
    width: 100%;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 18px 15px;
  }
  
  .input-field i {
    margin-left: 15px;
    color: #999;
  }
  
  form p {
    text-align: left;
    font-size: 13px;
  }
  
  form p a {
    text-decoration: none;
    color: #3c00a0;
  }
  
  .btn-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .btn-field button {
    flex-basis: 48%;
    background: #3c00a0;
    color: #fff;
    height: 50px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: background 1s;
  }
  
  .input-group {
    height: 320px;
  }
  
  .btn-field button.disable {
    background: #eaeaea;
    background: #555;
  }
  
  .my-node-enter {
    max-height: 0;
    opacity: 0;
    transition: max-height 300ms, opacity 300ms;
  }
  
  .my-node-enter-active {
    max-height: 60px;
    opacity: 1;
  }
  
  .my-node-exit {
    max-height: 60px;
    opacity: 1;
  }
  
  .my-node-exit-active {
    max-height: 0;
    opacity: 0;
    transition: max-height 300ms, opacity 300ms;
  }

  

  
  .active {
    background-color: #4caf50; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  
  .disable {
    background-color: #808080; 
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  
  .invisible {
    visibility: hidden;
  }
  
  
  .dashboard {
    padding: 20px;
    
  }

  
  .dashboard-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .dashboard h1 {
    font-size: 24px;
    color: #3c00a0;
    margin-bottom: 30px;
    text-align: center;
  }
  
  
  .dashboard a {
    margin: 10px;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    background-color: #3c00a0;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .dashboard a:hover {
    background-color: #33006f;
  }
  
  
  .dashboard .logout-btn {
    position: relative;
    color: white; 
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    border: none;
    background-color: black; 
    z-index: 1; 
    box-shadow: none; 
    border-radius: 0; 
  }
  
  .dashboard .logout-btn::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black; 
    opacity: 0.7; 
    pointer-events: none;
    z-index: -1;
  }
  
  .dashboard .logout-btn span {
    position: relative;
    z-index: 1;
  }
  
  .dashboard .logout-btn:focus,
  .dashboard .logout-btn:active {
    outline: none;
    background: black; 
  }
  
  nav .logout-btn , nav .home-btn{
    background: transparent;
    color: white;
    padding: 10px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }
  
  nav .logout-btn::before, nav .home-btn::before {
    display: none;
  }
  
  nav .logout-btn span {
    position: static;
  }
  
  nav .logout-btn:focus,
  nav .logout-btn:active, 
  nav .home-btn:focus,
  nav .home-btn:active{
    outline: none;
    background: transparent;
  }
  
.add-expense-form {
    width: 90%;
    max-width: 450px;
    margin: 50px auto;
    background: #fff;
    padding: 30px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .add-expense-form h1 {
    font-size: 24px;
    color: #3c00a0;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }
  
  .add-expense-form h1::after {
    content: "";
    width: 30px;
    height: 4px;
    border-radius: 3px;
    background: #3c00a0;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
  }
  
  .add-expense-form .input-field {
    background: #eaeaea;
    margin: 15px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-height: 65px;
    transition: max-height 0.5s;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #ccc; 
    transition: border-color 0.3s;
  }

  .add-expense-form .input-field:hover {
    border-color: #3c00a0;
  }
  
  .add-expense-form .input-field input,
  .add-expense-form .input-field textarea {
    width: 100%;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 18px 15px;
    border: none; 
  }
  
  .add-expense-form .input-field input[type="text"]::placeholder,
  .add-expense-form .input-field input[type="number"]::placeholder,
  .add-expense-form .input-field textarea::placeholder {
    text-align: left; 
  }
  
  .add-expense-form .input-field i {
    margin-left: 15px;
    color: #999;
  }
  
  .add-expense-form .btn-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .add-expense-form .btn-field button {
    flex-basis: 48%;
    background: #3c00a0;
    color: #fff;
    height: 50px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .add-expense-form .btn-field button.cancel-btn {
    background: #ccc;
  }
  
  .add-expense-form .btn-field button.cancel-btn:hover {
    background: #ddd;
  }
  
  .add-expense-form .btn-field button.submit-btn:hover {
    background: #33006f;
  }
  

  .add-expense-form .error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 10px;
  }
  
.add-expense-form .add-expense-btn {
  flex: 1; 
  background: #3c00a0;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 5px; 
}

.add-expense-form .close-form-btn{
    flex: 1; 
    background: red;
    color: #fff;
    height: 50px;
    padding: 0 20px; 
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 5px;
}




.add-expense-form .add-expense-btn:hover{
  background: #33006f; 
}

.add-expense-form .close-form-btn:hover {
    background-color: #990000; 
  }

.add-expense-form .add-expense-btn {
  margin-right: 5px; 
}

.add-income-form {
    width: 90%;
    max-width: 450px;
    margin: 50px auto;
    background: #fff;
    padding: 30px;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .add-income-form h1 {
    font-size: 24px;
    color: #3c00a0;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }
  
  .add-income-form h1::after {
    content: "";
    width: 30px;
    height: 4px;
    border-radius: 3px;
    background: #3c00a0;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
  }
  
  .add-income-form .input-field {
    background: #eaeaea;
    margin: 15px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    max-height: 65px;
    transition: max-height 0.5s;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    transition: border-color 0.3s;
  }
  
  .add-income-form .input-field:hover {
    border-color: #3c00a0; 
  }
  
  .add-income-form .input-field input,
  .add-income-form .input-field textarea {
    width: 100%;
    background: transparent;
    border: 0;
    outline: 0;
    padding: 18px 15px;
    border: none; 
  }
  
  .add-income-form .input-field input[type="text"]::placeholder,
  .add-income-form .input-field input[type="number"]::placeholder,
  .add-income-form .input-field textarea::placeholder {
    text-align: left; 
  }
  
  .add-income-form .input-field i {
    margin-left: 15px;
    color: #999;
  }
  
  .add-income-form .btn-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .add-income-form .btn-field button {
    flex-basis: 48%;
    background: #3c00a0;
    color: #fff;
    height: 50px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: background 0.3s;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .add-income-form .btn-field button.cancel-btn {
    background: #ccc;
  }
  
  .add-income-form .btn-field button.cancel-btn:hover {
    background: #ddd;
  }
  
  .add-income-form .btn-field button.submit-btn:hover {
    background: #33006f;
  }
  
  .add-income-form .error-message {
    color: #ff0000;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .add-income-form .add-income-btn {
    flex: 1; 
    background: #3c00a0;
    color: #fff;
    height: 50px;
    padding: 0 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 5px; 
  }
  
  .add-income-form .close-form-btn {
    flex: 1; 
    background: red;
    color: #fff;
    height: 50px;
    padding: 0 20px; 
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0 5px;
  }
  
  .add-income-form .add-income-btn:hover {
    background: #33006f;
  }
  
  .add-income-form .close-form-btn:hover {
    background-color: #990000; 
  }
  
  .add-income-form .add-income-btn {
  }

.add-expense-btn,
.add-income-btn {
  flex: 1;
  background: #3c00a0;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 5px;
}

.close-form-btn {
  flex: 1;
  background: red;
  color: #fff;
  height: 50px;
  padding: 0 20px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 5px;
}

.add-expense-btn:hover,
.add-income-btn:hover,
.close-form-btn:hover {
  background: #33006f;
}

  
.welcome-message {
    background-color: rgba(255, 255, 255, 0.1); 
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  
  .welcome-message h1 {
    color: whitesmoke;
    font-weight: 500;
    font-size: 32px;
    padding: 10px;
  }



.card-container {
    display: flex;
    justify-content: center; 
    gap: 20px; 
    margin-top: 40px;
    flex-wrap: wrap;
    border-radius: 10px 10px 10px 10px; 
  }
  
  .card {
    background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  text-decoration: none;
  color: #3c00a0;
  transition: all 0.2s ease-in-out;
  flex: 1;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 200px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px; 
  }
  
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
  
  .card img {
    width: 250px;
    height: 125px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 10px;

  }
  
  .card p {
    font-size: 18px;
    font-weight: 600;
    margin-top: 5px;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 0 0 10px 10px; 
  }

  .card-container a:hover {
  text-decoration: none;
}

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  filter: blur(10px);
  z-index: -1;
  transition: transform 0.3s;
}

.card:hover::before {
  transform: scale(1.1); 
}


  .expense-list,
  .income-list {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    flex: 1;
  }
  
  .expense-list h2,
  .income-list h2 {
    font-size: 24px;
    color: #3c00a0;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .expense-list table,
  .income-list table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .expense-list th,
  .income-list th,
  .expense-list td,
  .income-list td {
    padding: 10px;
  }
  
  .expense-list th {
    background-color: #f2f2f2;
    font-weight: 600;
  }
  
  .expense-list tr:nth-child(even),
  .income-list tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  
  .expense-list th:nth-child(1),
  .income-list th:nth-child(1),
  .expense-list td:nth-child(1),
  .income-list td:nth-child(1) {
    width: 20%;
  }
  
  .expense-list th:nth-child(2),
  .income-list th:nth-child(2),
  .expense-list td:nth-child(2),
  .income-list td:nth-child(2) {
    width: 50%;
  }
  
  .expense-list th:nth-child(3),
  .income-list th:nth-child(3),
  .expense-list td:nth-child(3),
  .income-list td:nth-child(3) {
    width: 20%;
  }
  
  .expense-list .total-amount,
  .income-list .total-amount {
    text-align: right;
    font-weight: bold;
    margin-bottom: 10px;
  }

  
  
  .expense-list p:nth-child(3)::before,
  .income-list p:nth-child(3)::before {
    content: "Date: ";
    font-weight: 600;
    white-space: nowrap;
  }
  
  .expense-list p:nth-child(1)::before,
  .income-list p:nth-child(1)::before {
    content: "Amount: $";
    font-weight: 600;
    white-space: nowrap;
  }
  
  .expense-list p:nth-child(2)::before,
  .income-list p:nth-child(2)::before {
    content: "Description: ";
    font-weight: 600;
  }
  
  .list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    margin-top: 20px;
  }
  
  .add-expense-btn,
  .add-income-btn {
    flex-basis: calc(50% - 10px);
    background: #3c00a0;
    color: #fff;
    height: 50px;
    padding: 0 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 10px; 
  }
  
  .close-form-btn {
    flex-basis: calc(50% - 10px);
    background: red;
    color: #fff;
    height: 50px;
    padding: 0 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-top: 10px;
  }
  
  .add-expense-btn:hover,
  .add-income-btn:hover,
  .close-form-btn:hover {
    background: #33006f;
  }

.balance-container {
  background-color:rgba(255, 255, 255, 0.1);
  text-align: center;
  margin-top: 30px;
  margin-bottom: 40px;
  border-radius: 10px;

}

.balance-container h2{
  color: whitesmoke;
  font-weight: 500;
  font-size: 32px;
  padding: 10px;
}

.balance {
  font-size: 36px;
  font-weight: bold;
  color: #3c00a0;
  padding: 10px 20px;
  border-radius: 10px;
  display: inline-block;
}

.balance-positive {
  color: green;
}

.balance-negative {
  color: red;
}

.chart-view {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #e1e1e1;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px; 
  width: 80%; 
  max-width: 700px;
  margin: 2% auto;
  overflow: hidden;
  position: relative;
  z-index: 1000;
}

canvas {
  display: block;
  background-color: #fff; 
  width: 100%;
  max-height: 400px; 
}




.about-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  color: #333; 
}

.about-container h1 {
  font-size: 24px;
  color: #3c00a0;
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.about-container h1::after {
  content: "";
  width: 30px;
  height: 4px;
  border-radius: 3px;
  background: #3c00a0;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}

.about-container p {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.6;
}

.contact-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
}

.contact-container h1 {
  font-size: 24px;
  color: #3c00a0;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.contact-content p {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.6;
}

.contact-info {
  list-style-type: none;
  padding: 0;
}

.contact-info li {
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.contact-info li svg {
  margin-right: 10px;
  color: #3c00a0;
}

.social-media {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social-icon {
  font-size: 24px;
  margin: 0 10px;
  color: #3c00a0;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #33006f;
}

.contact-form form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  padding: 10px 15px;
  background-color: #3c00a0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #33006f;
}


.error-message {
  color: #e74c3c;
  background-color: #fee; 
  padding: 10px;
  border: 1px solid #e74c3c;
  border-radius: 5px;
  margin: 5px 0;
  display: inline-block; 
  font-size: 14px; 
  max-width: 90%; 
  text-align: left;
  transition: opacity 0.3s, max-height 0.5s; 
}

.hidden {
  opacity: 0;
  max-height: 0;
  padding: 0; 
  border: none; 
  margin: 0; 
  overflow: hidden; 
}

.input-field.with-error {
  border-color: #e74c3c;
}

.input-field.with-error:hover {
  border-color: #e74c3c; 
}

.input-field.with-error input,
.input-field.with-error textarea {
  border-color: #e74c3c;
}

.success-message {
  color: #28a745; 
  background-color: #e6ffed; 
  border: 1px solid #c3e6cb; 
  border-radius: 5px; 
  padding: 10px; 
  margin: 10px 0; 
  text-align: center; 
  font-weight: bold;
}


.delete-button {
  margin-left: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 20px;
  color: red;
}


th:last-child,
td:last-child {
  min-width: 160px; 
}


.messages-container {
  height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
}

.message {
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
  background-color: #eaeaea;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-input {
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.send-btn {
  background-color: #3c00a0;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.send-btn:hover {
  background-color: #33006f;
}

.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}



.chatbot-container.closed .chat-interface {
  display: none;
}

.chat-avatar-icon {
  width: 50px;
  height: 50px; 
  border-radius: 50%; 
  object-fit: cover; 
}

.chatbot-container .chat-toggle {
  background-color: transparent;
  color: white;
  padding: 5px; 
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
  display: flex; 
  justify-content: center;
  align-items: center; 
  font-size: 16px;
  width: 60px; 
  height: 60px; 
}

.chat-interface {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 15px;
  width: 300px; 
  max-height: 500px; 
  overflow: hidden;
}

/* Styling for the close button */
.close-btn {
  color: red; 
  font-size: 24px; 
  transition: transform 0.3s ease; 
}

.close-btn:hover {
  transform: scale(1.1);
}

.api-key-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.api-key-modal-content {
  background: white;
  padding: 10px; 
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height:240px;
  width: 380px; 
}

h3 {
  margin-bottom: 5px; 
  text-align: center;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px; 
}

.api-key-input {
  flex-grow: 1; 
  padding: 8px 10px; 
  border: 1px solid #ccc;
  border-radius: 5px;
}

.show-api-key-btn {
  padding: 8px 15px; 
  background-color: #3c00a0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-password-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #3c00a0; 
  font-size: 20px; 
}

.api-key-input {
  flex-grow: 1;
  padding-right: 30px; 
}













